<template>
    <footer class="footer">
        <div class="footer__top">
            <a
                class="footer__phone"
                :href="`tel:${phoneLink}`"
            >
                <span class="icon-phone"></span>
                {{ phone }}
            </a>
        </div>
        <div class="footer__content">
            <h2>Изучите все условия кредита (займа)</h2>
            <br>
            <div class="footer__desc" v-html="text"></div>
        </div>
    </footer>
</template>

<script>
import Cookies from 'js-cookie';
import './footer.scss';

import contacts from '@/mixins/contacts';

export default {
    name: 'Footer',
    mixins: [
        contacts
    ],
    watch: {
        '$route'() {
            this.$forceUpdate()
        }
    },
    computed: {
        text() {
            if (this.$route.name === 'Main' && this.$DICTIONARY?.footerTextIndex)
                return this.$DICTIONARY?.footerTextIndex

            if (!Cookies.get('sbg-in'))
                return this.$DICTIONARY?.footerTextFirst || this.$DICTIONARY?.footerText

            return this.$DICTIONARY?.footerText
        }
    }
}
</script>